<template>
    <split-pane  :min-percent='20' :default-percent='50' split="vertical">
        <template slot="paneL">
            <el-tabs   :animated="false"  @tab-click="tabClick" v-model="tabName" style="background:white;margin-top:5px;">
                <el-tab-pane name='user'>
                    <span slot="label" style="padding-left:15px;" ><i class="iconfont icon-kehu"></i> 用户权限</span> 
                    <div style=" margin-bottom:1px;background:#f8f8f9;line-height:40px;height:40px; padding-left:5px;border-left:3px solid #409eff">
                        <div style="width:800px;">
                            <i class="iconfont icon-xinzeng" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                            <el-link @click="addRec" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;margin-right:15px;color:#409EFF;">分配角色</span></el-link>
                            <i class="iconfont icon-fuzhi" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                            <el-link @click="copyRec" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;color:#409EFF;">复制用户</span></el-link>
                           
                            <i class="iconfont icon-baocun" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:15px;"></i>
                            <el-link @click="saveRec" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;color:#8a1c1c;">保存权限</span></el-link>
                            <i class="iconfont icon-shanchu" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:15px;"></i>
                            <el-link @click="clearRec" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;color:#8a1c1c;">清空用户权限</span></el-link>
                            <i class="iconfont icon-kehu" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:15px;"></i>
                            <el-link @click="hrRec" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;color:#8a1c1c;">用户资料管理</span></el-link>
                            <el-input :placeholder="input_hold" v-model="textparam" size="small" class="selinput" @keyup.enter.native="qryTabData">
                                <el-select v-model="selparam" slot="prepend" placeholder="请选择列名" clearable  @change="elchange" style="width:120px;background:white;height:32px;border:1px solid #ccc">
                                    <span v-for="(item ) in userCol" :key='item.key'>
                                        <el-option :label="item.title" :value="item.key"></el-option>
                                    </span>
                                </el-select>
                                <el-button slot="append" icon="el-icon-search" @click="qryTabData" style="font-size:14px;color:#409EFF"></el-button>
                            </el-input> 
                            
                        </div>
                    </div>
                    <el-table  :data="userData" 
                            border                            
                            size="small"
                            ref="refuser"
                            :row-key="getRowKey"
                            highlight-current-row
                            @row-click="rowClick"
                            :height="tabHigh" >
                        <el-table-column  v-for="(item ) in userCol" :key="item.key"
                            :prop="item.key"
                            :label="item.title"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            :sortable="item.key!=='RN'&&item.edcss!=='checkbox'"
                            :min-width="item.width">
                            <template slot-scope="scope">
                                <el-checkbox disabled true-label="Y" false-label="N" v-if="item.edcss=='checkbox'" v-model="scope.row[item.key]"></el-checkbox>
                                <span v-else v-text="scope.row[item.key]"></span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <el-button size="mini"   type="text" icon="iconfont icon-shuaxin" @click="resetPwd(scope.row )"  style="margin-right:3px;">重置密码</el-button> 
                            </template>
                        </el-table-column>  
                    </el-table>
                    <el-dialog  :visible.sync="userModal" width="580px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="4vh">
                        <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                            <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">分配角色</span>
                        </div>
                        
                        <el-checkbox-group v-model="checkList">
                            <span v-for="(item ) in roleArr" :key='item.IDSEQ'>
                                <el-checkbox :label="item.IDSEQ" style="width:180px;line-height:30px;">{{item.ROLENM}}</el-checkbox>
                            </span>
                        </el-checkbox-group>
                        <div slot="footer">
                            <Button type="text"   @click="userModal=false" style="margin-right:20px;">取消</Button>
                            <Button type="primary"   @click="user_ok">确定</Button>
                        </div>
                    </el-dialog>
                </el-tab-pane>
                <el-tab-pane name='role'>
                    <span slot="label"><i class="iconfont icon-hezuo1"></i> 角色权限</span> 
                    <div style=" margin-bottom:1px;background:#f8f8f9;line-height:40px;height:40px; padding-left:5px;border-left:3px solid #67C23A">
                        <div style="width:500px;">
                        <i class="iconfont icon-xinzeng" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                        <el-link @click="addRole" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;margin-right:15px;color:#8a1c1c;">新增角色</span></el-link>
                        <i class="iconfont icon-bianji" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                        <el-link @click="editRole" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;margin-right:15px;color:#8a1c1c;">编辑角色</span></el-link>
                        <i class="iconfont icon-shanchu1" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                        <el-link @click="delRole" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;margin-right:15px;color:#8a1c1c;">删除角色</span></el-link> 
                        <i class="iconfont icon-baocun" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:15px;"></i>
                        <el-link @click="saveRec" :underline="false" style="margin-top:-2px;"><span style="font-size:14px;color:#8a1c1c;">保存角色权限</span></el-link>   
                        </div>
                    </div>
                    <el-table  :data="roleData" 
                        border                            
                        size="small"
                        ref="refrole"
                        :row-key="getRoleKey"
                        :height="tabHeight" 
                        highlight-current-row
                        @row-click="roleClick" >
                        <el-table-column
                            type="index"
                            fixed
                            width="40">
                        </el-table-column>
                        <el-table-column  v-for="(item  ) in roleCol" :key="item.key"
                            :prop="item.key"
                            :label="item.title"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            sortable
                            :min-width="item.width">
                        </el-table-column>                        
                    </el-table>
                    <el-dialog  :visible.sync="roleModal" width="720" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="4vh">
                        <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                            <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">角色操作</span>
                        </div>    
                        <Form ref="roleForm" :model="rowRoleCol" :label-width="100"  :rules="roleValidate"  label-position="right" inline >
                            <span v-for="(item ) in roleCol" :key="item.key" >
                                <FormItem :label="item.title" :prop="item.key" v-if="item.fhide==='N'">                                  
                                    <el-checkbox border v-if="item.EDCSS==='checkbox'" true-label="Y"  false-label="N" v-model="rowRoleCol[item.key]" style="width:140px;"></el-checkbox>    
                                    <Input v-else type="text" :disabled="item.disabled=='Y'?true:false " v-model="rowRoleCol[item.key]"  style="width:220px;" clearable/>
                                </FormItem>
                            </span>
                        </Form>
                        <div slot="footer">
                            <Button type="text"   @click="roleModal=false">取消</Button>
                            <Button type="primary"   @click="role_ok">确定</Button>
                        </div>
                    </el-dialog>
                </el-tab-pane>
            </el-tabs>
            <!-- 人员资料 -->
            <div v-if="hrDiv">
                <el-dialog :before-close="setHrDiv" :visible.sync="hrWin" width="90%" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="0vh">
                    <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                        <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">用户信息 </span>
                    </div>
                    <hremp :leftBool="true" :empBool="true" :empNo="userno"></hremp>
                    
                </el-dialog>
            </div>
        </template>
        <!-- 右侧内容 -->
        <template slot="paneR">
                <el-tabs :animated="false" style="background:#fff">
                    <el-tab-pane> 
                        <span slot="label" style="padding-left:15px;" ><i class="el-icon-menu"></i> {{modFormNm}}</span> 
                        <div style="overflow:auto" :style="{height:tableHigh}">
                            <template v-for="(item, index) in menuList" > 
                                <div  class="oneMenu" :key="index" >
                                    <div style=" width:200px;"> {{item.name}} <i @click="menuClick(index,item.code)" :class="listIndex===index&&menuBool?'el-icon-arrow-up':'el-icon-arrow-down'" style="margin-left:10px;"></i> </div>
                                    <div style=" width:80px; ">查看<el-checkbox v-model="item.sel" @change="modSelect('S',index,item.sel)" style="margin-left:5px;" false-label="N"  true-label="Y"></el-checkbox></div>
                                    <div style=" width:80px; ">新增<el-checkbox v-model="item.ins" @change="modSelect('I',index,item.ins)" style="margin-left:5px;" false-label="N"  true-label="Y"></el-checkbox></div>
                                    <div style=" width:80px; ">修改<el-checkbox v-model="item.upd" @change="modSelect('U',index,item.upd)" style="margin-left:5px;" false-label="N"  true-label="Y"></el-checkbox></div>
                                    <div style=" width:80px; ">删除<el-checkbox v-model="item.del" @change="modSelect('D',index,item.del)" style="margin-left:5px;" false-label="N"  true-label="Y"></el-checkbox></div>
                                    <div style=" width:80px; ">审核<el-checkbox v-model="item.cfm" @change="modSelect('C',index,item.cfm)" style="margin-left:5px;" false-label="N"  true-label="Y"></el-checkbox></div>
                                    <div style=" width:80px; ">改他人<el-checkbox v-model="item.oth" @change="modSelect('O',index,item.oth)" style="margin-left:5px;" false-label="N"  true-label="Y"></el-checkbox></div>
                                </div>
                                <template v-for="(subitem  ) in item.children">
                                    <span :key="subitem.code"  v-if="openName.indexOf(item.code)>-1">
                                        <div  class="twoMenu">
                                            <div style="display:flex">
                                                <div style=" width:200px;" >{{subitem.name}} </div>
                                                <div style=" width:80px;margin-left:14px;"><el-checkbox v-model="subitem.sel" style="margin-left:5px;" false-label="N"  true-label="Y"></el-checkbox></div>
                                                <div style=" width:80px; "><el-checkbox v-model="subitem.ins" style="margin-left:5px;" false-label="N"  true-label="Y"></el-checkbox></div>
                                                <div style=" width:80px; "><el-checkbox v-model="subitem.upd" style="margin-left:5px;" false-label="N"  true-label="Y"></el-checkbox></div>
                                                <div style=" width:80px; "><el-checkbox v-model="subitem.del" style="margin-left:5px;" false-label="N"  true-label="Y"></el-checkbox></div>
                                                <div style=" width:80px; "><el-checkbox v-model="subitem.cfm" style="margin-left:5px;" false-label="N"  true-label="Y"></el-checkbox></div>
                                                <div style=" width:80px; "><el-checkbox v-model="subitem.oth" style="margin-left:18px;" false-label="N"  true-label="Y"></el-checkbox></div>
                                            </div>
                                        </div>
                                    </span>
                                </template>
                            </template>     
                        </div> 
                        <el-dialog  :visible.sync="cpModal" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="4vh">
                            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">复制用户权限</span>
                            </div> 
                            <span style="margin-left:15px;">选择参考用户</span>
                            <el-select v-model="cpuser" size="small"  placeholder="请选择参考用户" clearable  style="width:330px;margin-left:10px;">
                                <span v-for="(item ) in loginArr" :key='item.IDSEQ'>
                                    <el-option :label="item.USERNM" :value="item.IDSEQ">
                                        <span style="display:inline-block;width:50% ">{{ item.USERNO }}</span>
                                        <span style="display:inline-block;">{{ item.USERNM}}</span>
                                    </el-option>
                                </span>
                            </el-select>
                            <div slot="footer" style="height:35px;">
                                <Button type="primary" style="margin-right:25px;"  @click="cpModal=false">取消</Button>
                                <Button type="primary"   @click="copy_ok">确定</Button>
                            </div> 
                        </el-dialog>
                    </el-tab-pane>
                    <el-tab-pane>  
                        <span slot="label" style="padding-left:15px;"><i class="iconfont icon-huiyuanzhongxin1"></i> 前端会员中心权限</span> 
                        <div style="overflow:auto" :style="{height:tableHigh}">
                            <template v-for="(item, index) in memberList" > 
                                <div  class="oneMenu" :key="index" >
                                    <div style=" width:200px;"> {{item.name}} <i @click="memClick(index,item.code)" :class="memIndex===index&&memBool?'el-icon-arrow-up':'el-icon-arrow-down'" style="margin-left:10px;"></i> </div>
                                    <div style=" width:80px; ">查看<el-checkbox v-model="item.sel" @change="memSelect('S',index,item.sel)" style="margin-left:5px;" false-label="N"  true-label="Y"></el-checkbox></div>
                                </div>
                                <template v-for="(subitem  ) in item.children">
                                    <span :key="subitem.code"  v-if="openMem.indexOf(item.code)>-1">
                                        <div  class="twoMenu">
                                            <div style="display:flex">
                                                <div style=" width:200px;" >{{subitem.name}} </div>
                                                <div style=" width:80px;margin-left:14px;"><el-checkbox v-model="subitem.sel" style="margin-left:5px;" false-label="N"  true-label="Y"></el-checkbox></div>
 
                                            </div>
                                        </div>
                                    </span>
                                </template>
                            </template>     
                        </div> 
                    </el-tab-pane>                   
 
                    <el-tab-pane>  
                        <span slot="label"><i class="iconfont icon-kehu"></i> 拥有客户清单</span> 
                         <div style="border-left:2px solid #409eff;width:800px;padding-left:5px;" >
                            <i class="iconfont icon-xinzeng" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                            <el-link @click="addClnt" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;margin-right:15px;">新增客户</span></el-link> 
                            <span style=" margin-left:20px;font-size:12px;">字段查询</span>        
                            <el-input :placeholder="input_hold" v-model="clttext" @keyup.enter.native="stfgClnt('')" size="small" style="width:430px;margin-left:10px;vertical-align:center">
                                <el-select v-model="cltsel" slot="prepend" placeholder="请选择列名" clearable  style="width:140px;">
                                    <span v-for="item in cltCol" :key='item.key'>
                                        <el-option :label="item.title" :value="item.key" v-if="item.thide==='N'"></el-option>
                                    </span>
                                </el-select>
                                <el-button slot="append" icon="iconfont icon-chakan-copy" @click="stfgClnt('')"></el-button>
                            </el-input> 
                        </div>
                         <el-table  :data="cltData"  
                            border                            
                            size="small"
                            ref="refclt"
                            :height="cltHeight"
                            :row-key="getCltKey"
                            highlight-current-row
                            style="overflow:auto;margin-top:1px;"> 
                            <span v-for="item in cltCol" :key="item.key" > 
                                <el-table-column  
                                    v-if="item.thide==='N' && item.key!=='ISOK'&& item.key!=='STFG'"
                                    :prop="item.key"
                                    :fixed="item.key==='RN'?true:false"
                                    :label="item.title"
                                    show-overflow-tooltip
                                    :align="item.align"                                  
                                    sortable
                                    :min-width="item.width">
                                </el-table-column>
                            </span>
                            <el-table-column
                                fixed="right"
                                label="操作"
                                align="center"
                                width="140">
                                <template slot-scope="scope">
                                    <el-button size="small"   type="text" icon="iconfont icon-bianji" @click="cltEdit(scope.row )">编辑</el-button>
                                    <el-button size="small"  type="text" icon="iconfont icon-shanchu" @click="cltDel(scope.row,scope.$index )">删除</el-button>  
                                </template>
                            </el-table-column>                           
                        </el-table>
                        <div style="margin-top: 2px;margin-bottom:2px;overflow: hidden">
                            <div style="float: left;">
                                <Page :total="cltCount" :page-size="pageSize" show-total :current="1" @on-change="chgPage"></Page>
                            </div>
                        </div> 
                    </el-tab-pane>
                    <el-tab-pane  >  
                        <span slot="label"><i class="iconfont icon-gongyingshangguanli-"></i> 拥有供应商清单</span>
                        <div style="border-left:2px solid #409eff;width:800px;padding-left:5px;" >
                            <i class="iconfont icon-xinzeng" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                            <el-link @click="addSup" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;margin-right:15px;">新增供应商</span></el-link>  
                            <span style=" margin-left:20px;font-size:12px;">字段查询</span>        
                            <el-input :placeholder="input_hold" v-model="suptext" @keyup.enter.native="stfgChange('')" size="small" style="width:420px;margin-left:10px;vertical-align:center">
                                <el-select v-model="supsel" slot="prepend" placeholder="请选择列名" clearable  style="width:140px;">
                                    <span v-for="item in supCol" :key='item.key'>
                                        <el-option :label="item.title" :value="item.key" v-if="item.thide==='N'"></el-option>
                                    </span>
                                </el-select>
                                <el-button slot="append" icon="iconfont icon-chakan-copy" @click="stfgChange('')"></el-button>
                            </el-input> 
                        </div>
                            <el-table  :data="supData"  
                                border                            
                                size="small"
                                ref="refsup"
                                v-loading="loading"
                                element-loading-text="加载中"
                                element-loading-spinner="el-icon-loading"
                                element-loading-background="rgba(255, 251, 255, 0.8)"
                                :height="subHeight" 
                                :row-key="getSupKey"
                                highlight-current-row
                                style="overflow:auto;margin-top:1px;"> 
                                <span v-for="item  in supCol" :key="item.key" > 
                                    <el-table-column  
                                        v-if="item.thide==='N' && item.key!=='ISOK'&&item.key!=='STFG'"
                                        :prop="item.key"
                                        :fixed="item.key==='RN'?true:false"
                                        :label="item.title"
                                        show-overflow-tooltip
                                        :align="item.align"                                  
                                        sortable
                                        :min-width="item.width">
                                    </el-table-column>
                                 </span>
                                <el-table-column
                                    fixed="right"
                                    label="操作"
                                    align="center"
                                    width="140">
                                    <template slot-scope="scope">
                                        <el-button size="small"   type="text" icon="iconfont icon-bianji" @click="supEdit(scope.row )">编辑</el-button>
                                        <el-button size="small"  type="text" icon="iconfont icon-shanchu" @click="supDel(scope.row,scope.$index)">删除</el-button>  
                                    </template>
                                </el-table-column>                       
                            </el-table>
                            <div style="margin-top: 2px;margin-bottom:2px;overflow: hidden">
                                <div style="float: left;">
                                    <Page :total="dataCount" :page-size="pageSize" show-total :current="1" @on-change="changePage"></Page>
                                </div>
                            </div> 
                         
                    </el-tab-pane>
                </el-tabs>  
                <!-- 提示框 -->
                <el-dialog  :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
                    <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                        <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">提示</span>
                    </div>
                    <div style=" margin:15px 10px">
                        {{prompt}}
                    </div>
                    <div slot="footer">
                        <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
                    </div>
                </el-dialog>
                <!-- 确认框 -->
                <el-dialog  :visible.sync="confirmWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
                    <div style=" margin:15px 10px">
                        {{cfmprompt}}
                    </div>
                    <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                        <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">提示</span>
                    </div>
                    <div slot="footer">
                        <Button type="primary"   @click="confirmWin=false" style="margin-right:40px;">取消</Button>
                        <Button type="primary"   @click="cfmOk(cfmVal)" style="margin-right:20px;">确定</Button>
                    </div>
                </el-dialog>
            <!-- 增加客户 -->
            <el-dialog  :visible.sync="cltWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="4vh">
                <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                    <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">新增客户</span>
                </div> 
                <div style="height:80px;margin-top:20px;">
                    <span style="margin-left:15px;">选择客户</span>
                    <el-select v-model="clntno"    placeholder="请选择客户" clearable filterable style="width:330px;margin-left:10px;">
                        <span v-for="(item ) in clntArr" :key='item.index'>
                            <el-option :label="item.CLNTNM" :value="item.IDSEQ"></el-option>
                        </span>
                    </el-select> 
                </div>
                <div slot="footer">
                    <Button type="text"   @click="cltWin=false" style="margin-right:50px">取消</Button>
                    <Button type="primary"   @click="cltSave">确定</Button>
                </div>
            </el-dialog>
            <!-- 增加供应商 -->
            <el-dialog  :visible.sync="supWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="4vh">
                <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                    <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">新增客户</span>
                </div> 
                <div style="height:80px;margin-top:20px;">
                    <span style="margin-left:15px;">选择供应商</span>
                    <el-select v-model="supno"    placeholder="请选择供应商" clearable filterable style="width:330px;margin-left:10px;">
                        <span v-for="(item ) in supArr" :key='item.index'>
                            <el-option :label="item.SUPNM" :value="item.IDSEQ"></el-option>
                        </span>
                    </el-select> 
                </div>
                <div slot="footer">
                    <Button type="text"   @click="supWin=false" style="margin-right:50px">取消</Button>
                    <Button type="primary"   @click="supSave">确定</Button>
                </div>
            </el-dialog>
            <el-dialog :show-close="false" :visible.sync="pwdWin" width="440px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false"   >
                <Form  v-model="pwdform" :rules="pwdValidate" :label-width="110" ref="pwdform"  label-position="right" inline > 
                    <FormItem  label="新密码"  style="width:95%;margin-top:15px;" prop="NEWPWD">                  
                        <el-input  show-password
                                placeholder="请输入新密码(长度不少于6位)"
                                type="password"
                                v-model="NEWPWD"> 
                            <template slot ="prepend" ><i class="el-icon-lock" style="color:#409EFF"></i></template>
                        </el-input>           
                    </FormItem>
                    <FormItem  label="确认密码"  style="width:95%" prop="CFMPWD">                  
                        <el-input  show-password
                                placeholder="请确认新密码(长度不少于6位)"
                                type="password"
                                v-model="CFMPWD"> 
                            <template slot ="prepend" ><i class="el-icon-lock" style="color:#409EFF"></i></template>
                        </el-input>           
                    </FormItem>
                </Form>
                <div slot="footer"  >
                    <Button type="text"    @click="pwdWin=false" style="margin-right:25px;">取消</Button> 
                    <Button type="primary" plain   @click.native="pwdCfm" style="margin-right:25px;">确定</Button>            
                </div>
                <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                    <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px; ">重置用户密码</span>
                </div>
            </el-dialog>
        </template>
    </split-pane>    
</template>
<script>
import { getTabColOrData,getBaseData } from '../../api/user' 
import { staffArray,roleArray,loginArray,paramData} from '../../api/Select'
import {filterArrObj } from '../../api/Tools'
import hremp from '../hrmgt/hr_tmpl.vue' 
export default {
    name: 'sys_userpriv',
    props:['EMPNO'],
    data () {
        return {
            pwdWin:false,
            NEWPWD:'',
            CFMPWD:'',
            pwdform:{},
            supWin:false,
            pwdValidate: {NEWPWD:[{required:true,trigger:'blur',message:'新密码不能为空'}] ,
                     CFMPWD:[{required:true,trigger:'blur',message:'确认密码不能为空'}],
            },  
            modFormNm:'自定义权限',
            supno:'',
            supArr:[],
            clntno:'',
            LSTSEQ:'',
            clntArr:[],
            userModal:false, //modal 
            confirmWin:false,
            cfmprompt:false,
            promptWin:false,
            hrDiv:false,
            prompt:'',
            cfmVal:'',
            cltData:[],
            supsel:'IDNO',// select 绑定值
            suptext:'', // input 绑定值
            cltsel:'IDNO',// select 绑定值
            clttext:'', // input 绑定值
            userno:'',
            supCol:[],
            cltCol:[],
            cltWin:false,
            roleModal:false,
            menuBool:false,
            cpModal:false,
            editFlag:'',
            cpuser:'',
            v_userno:'',
            rowRoleCol:{},
            memBool:false,
            stfgSet:'ALL',
            cltSet:'ALL',
            checkList:[],
            isdeter:true,
            input_hold:'请输入内容... 按回车键以查询',
            selparam:'',// select 绑定值
            textparam:'', // input 绑定值
            userCol:[] ,//表列集合
            userFormCol:[] ,//表列集合
            userData:[] ,// 用户表
            hrWin:false,
            v_url:this.$store.state.queryUrl , //api请求路径     
            v_likeurl:this.$store.state.baseUrl+'sysprivs/getManyRecord', //多记录查询 like模式    
            v_formurl:this.$store.state.baseUrl+'user/getModForm', //用户模块表单    
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            loading:false,// table加载bool
            elload:false,// table加载bool
            tabHeight:'720px',
            rowIdseq:'-1',
            type:'create',
            ruleValidate: {},
            roleValidate: {ROLENM:[{required:true,trigger:'blur',message:'角色名称不能为空'}]},
            stfgBool:true,
            formCol:{}, //表格单行记录 model
            roleArr:[] , //角色集合
            rolenm:'' , //角色名称
            staffRowArr:{} , //在职单行用户
            roleCol:[], //角色表字段
            roleData:[] ,//角色数据
            staffArr:[] , //在职用户  
            roleindex:'-1',
            loginArr:[],
            rowCol:{}, 
            tabName:'user',
            menuList:[],
            //部门
            memberList: [],
            supData:[],
            allData:[],
            allClnt:[],
            memIndex:'-1',
            v_depturl:this.$store.state.baseUrl+'sysprivs/getManyRecList', //多记录查询   
            openMem:[],
            openName:[],
            listIndex:'-1',
            dataCount:0,
            cltCount:0,
            pageSize:20,
        }
    },
    created () {
      this.getTabCol() //用户列名字段 
      this.getMenuList() //模块列数组
      this.getMemList() //会员中心菜单
      if (!this.EMPNO){
        this.getFormData('','','','','','',' ORDER BY ADDDTTM DESC') //初始查询用户表数据    
      }
      this.getRoleCol()  //角色列名字段
    },
    components: { 
      hremp, //人员资料
    },
    computed: {
        tabHigh(){
            return (window.innerHeight- 195)  + 'px'
        },
        tableHigh(){
            return (window.innerHeight- 166)  + 'px'
        },
        subHeight(){
            return (window.innerHeight- 220)  + 'px'
        },
        cltHeight(){
            return (window.innerHeight- 220)  + 'px'
        },
        selidseq(){
            if (this.tabName ==='user'){
                return this.rowIdseq
            }else{
                return this.roleindex
            }
        }
    },
    mounted () {
        if(this.v_username && this.v_password){
            this.tabHeight = (window.innerHeight- 220) //+ 'px'
            //在职用户
            staffArray('','').then((res)=>{
                this.staffArr =res.data.result
            });
            //角色
            roleArray().then((res)=>{
                this.roleArr =res.data.result
            });
            //登录用户
            loginArray().then((res)=>{
                this.loginArr =res.data.result
            });
            //客户
            getBaseData(this.$store.state.nologinUrl, '','','','','V_bs_clnt','').then(res=>{
                this.clntArr=res.data.result
            }) 
            //供应商
 
            paramData('STFG','Y' ,'V_COMBO_SUP').then((res)=>{               
                this.supArr =res.data.result
            })
        }else{
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self'); 
        }
    } ,
    watch: {
 
    },
    methods: { 
        resetPwd(row){
            this.pwdWin=true
            this.pwdform={}
            this.v_userno=row['USERNO']
        },
        //密码修改
        pwdCfm(){  //确定新增
            let vm=this     
            let pwd_ =this.NEWPWD
            let cfmpwd_=this.CFMPWD
            if (!pwd_){
                this.promptWin=true
                this.prompt='新密码不能为空'
            }else if (!cfmpwd_){
                this.promptWin=true
                this.prompt='确认密码不能为空'
            }else{
                if(pwd_.length<6){
                    this.promptWin=true
                    this.prompt='密码长度不能少于6位'
                }else if(cfmpwd_.length<6){
                    this.promptWin=true
                    this.prompt='确认密码长度不能少于6位'
                }else if(pwd_!==cfmpwd_){
                    this.promptWin=true
                    this.prompt='新密码和确认密码不匹配,请核查'
                }else{
                    vm.$axios({
                        method: 'post',
                        url:vm.$store.state.baseUrl+'user/updatePassword?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                        data: {p_value:vm.v_userno, p_table:'sys_user',p_value2:pwd_},
                    }).then(res=>{      
                        if(res.data.result ==='OK')  {          
                            vm.pwdWin=false   
                        }else{
                            this.promptWin=true
                            this.prompt=res.data.result
                        }        
                    })     
                }         
            }
        },
        supEdit(row){
            this.supWin=true
            this.supno=row['CLTIDSEQ']
            this.LSTSEQ=row['LSTSEQ']
        },
        supDel(row,index){
            this.$Modal.confirm({
                title: '删除确认',
                content: '<p>你确定要删除此行记录吗</p>',
                onOk: () => {
                    //发起删除请求
                    var vm=this;
                    this.$axios({
                        method: 'post',
                        url:this.$store.state.baseUrl+'auth/login/delTabRec',
                        data: {p_key:'idseq',p_value:row.IDSEQ,p_key2:'lstseq',p_value2:row.LSTSEQ,p_table:'sys_user_priv'},
                    }).then(res=>{      
                        if(res.data.code =='200')  {      
                            this.supData.splice(index,1)  
                        }else{
                            this.$Message.error(res.data.message)
                        }        
                    }) 
                }
            })
        },
        //客户记录操作
        cltDel(row,index){
            this.$Modal.confirm({
                title: '删除确认',
                content: '<p>你确定要删除此行记录吗</p>',
                onOk: () => {
                    //发起删除请求
                    var vm=this;
                    this.$axios({
                        method: 'post',
                        url:this.$store.state.baseUrl+'auth/login/delTabRec',
                        data: {p_key:'idseq',p_value:row.IDSEQ,p_key2:'lstseq',p_value2:row.LSTSEQ,p_table:'sys_user_priv'},
                    }).then(res=>{      
                        if(res.data.code =='200')  {      
                            this.cltData.splice(index,1)  
                        }else{
                            this.$Message.error(res.data.message)
                        }        
                    }) 
                }
            })
        },
        cltEdit(row){
            this.cltWin=true
            this.clntno=row['CLTIDSEQ']
            this.LSTSEQ=row['LSTSEQ']
        },
        getSupKey(row){
            return row.IDSEQ+'-'+row.LSTSEQ
        },
        getCltKey(row){
            return row.IDSEQ+'-'+row.LSTSEQ
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.pageSize;
            var _end = index * this.pageSize;
            this.supData = this.allData.slice(_start,_end);
        },  
        chgPage(index){
            var _start = ( index - 1 ) * this.pageSize;
            var _end = index * this.pageSize;
            this.cltData = this.allClnt.slice(_start,_end);
        }, 
        supSave(){
           if  (this.selidseq!=='-1'){
                if (this.supno){
                    let obj_={IDSEQ:this.selidseq,LSTSEQ:this.LSTSEQ,PRIVTY:'SUP',MODIDSEQ:this.supno,ISSEL:'Y'}
                    this.$axios({
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/tableBodySave?username='+this.v_username+'&password='+this.v_password,
                        data: {p_json:JSON.stringify(obj_), p_table:'sys_user_priv',p_idseq:''},
                    }).then(res=>{   
                        if(res.data.code ==='200')  {   
                            this.supWin=false       
                            this.stfgChange('')
                        }else{
                            this.prompt =res.data.result
                            this.promptWin=true
                        }        
                    })  
                }else{
                    this.promptWin=true
                    this.prompt='请选择供应商'
                }
            }else{
               this.promptWin=true
               this.prompt='请选择用户或角色'
            }
        },
        // 客户保存
        cltSave(){
            if  (this.selidseq!=='-1'){
                if (this.clntno){
                    let obj_={IDSEQ:this.selidseq,LSTSEQ:this.LSTSEQ,PRIVTY:'CLT',MODIDSEQ:this.clntno,ISSEL:'Y'}
                    this.$axios({
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/tableBodySave?username='+this.v_username+'&password='+this.v_password,
                        data: {p_json:JSON.stringify(obj_), p_table:'sys_user_priv',p_idseq:''},
                    }).then(res=>{   
                        if(res.data.code ==='200')  {   
                            this.cltWin=false       
                            this.stfgClnt('')
                        }else{
                            this.prompt =res.data.result
                            this.promptWin=true
                        }        
                    })  
                }else{
                    this.promptWin=true
                    this.prompt='请选择客户'
                }
            }else{
               this.promptWin=true
               this.prompt='请选择用户或角色'
            } 
        },
        //状态查询
        stfgChange(idseq){
            if (idseq){
                this.qrySupData(' and '+this.supsel+' like \'%'+this.suptext+'%\' and idseq='+idseq)
            }else{
                this.qrySupData(' and '+this.supsel+' like \'%'+this.suptext+'%\' and idseq='+this.selidseq)  
            }
        },
        stfgClnt(idseq){
            if (idseq){
                this.qryCltData(' and '+this.cltsel+' like \'%'+this.clttext+'%\' and idseq='+idseq)  
            }else{
                this.qryCltData(' and '+this.cltsel+' like \'%'+this.clttext+'%\' and idseq='+this.selidseq)  
            }
        },
        //查询supply信息
        qrySupData( cond){
            let vm=this
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_USER_SUP','','','','','','', cond).then((res) => {
                //保存取到 所有数据
                vm.allData =res.data
                vm.dataCount =res.data.length //总条数
                // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
                if (res.data.length < vm.pageSize){
                    vm.supData = vm.allData
                }else{
                    vm.supData =vm.allData.slice(0,vm.pageSize) //返回选定元素的子数组，不包含结尾元素
                }
                //希望在 DOM 元素中某些属性发生变化之后重新应用该插件
                this.$nextTick(() => { //有固定列时 查询后重新布局
                    this.$refs.refsup.doLayout();
                }) 
            })
        },
        //查询supply信息
        qryCltData( cond){
            let vm=this
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_USER_CLNT','','','','','','', cond).then((res) => {
                //保存取到 所有数据
                vm.allClnt =res.data
                vm.cltCount =res.data.length //总条数
                // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
                if (res.data.length < vm.pageSize){
                    vm.cltData = vm.allClnt
                }else{
                    vm.cltData =vm.allClnt.slice(0,vm.pageSize) //返回选定元素的子数组，不包含结尾元素
                }
                //希望在 DOM 元素中某些属性发生变化之后重新应用该插件
                this.$nextTick(() => { //有固定列时 查询后重新布局
                    this.$refs.refclt.doLayout();
                }) 
            })
        },
        //将子组件包裹起来，当v-if=false 时可以将子组件销毁掉，再次调用时会重新渲染
        setHrDiv(){
            this.hrDiv=false
        },
        //人员资料
        hrRec(){
            if (this.userno){
                this.hrDiv=true
                this.hrWin =true
            }else{
                this.promptWin =true
                this.prompt='请选择一条用户记录'
            }
 
        },
        //确认框 
        cfmOk(val){
            let vm =this
            if (val==='copy'){ //用户复制
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'user/copyUserPriv?username='+vm.v_username+'&password='+vm.v_password,
                    data: {p_key:'p_fridseq',p_value:this.cpuser,p_key2:'p_toidseq',p_value2:vm.rowIdseq,p_action:'copy'},
                }).then(res=>{      
                    if(res.data.code =='200')  {      
                        vm.qryTabData() 
                        this.cpModal =false    
                    }else{
                        this.promptWin=true
                        this.prompt=res.data.message
                    }        
                }) 
            }else if (val==='clear'){ //清空用户权限
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'user/copyUserPriv?username='+vm.v_username+'&password='+vm.v_password,
                    data: {p_key:'p_fridseq',p_value:'0',p_key2:'p_toidseq',p_value2:vm.rowIdseq,p_action:'CLEAR'},
                }).then(res=>{      
                    if(res.data.code =='200')  {      
                        vm.qryTabData() 
                    }else{
                        this.promptWin=true
                        this.prompt=res.data.message
                    }        
                }) 
            }
            this.confirmWin =false
        },
        getRowKey(row){
            return row.IDSEQ
        }, 
        getRoleKey(row){
            return row.IDSEQ
        },
        //会员选择
        memSelect(oper,index,value){
            if (value==='Y'){
                for (let k=0;k<this.memberList[index].children.length;k++){
                    if (oper==='S'){
                        this.memberList[index].children[k].sel='Y'
                    }
                }
            }else{
                for (let k=0;k<this.memberList[index].children.length;k++){
                    if (oper==='S'){
                        this.memberList[index].children[k].sel='N'
                    }
                }
            }
        },
        //模块选择
        modSelect(oper,index,value){
            if (value==='Y'){
                for (let k=0;k<this.menuList[index].children.length;k++){
                    if (oper==='S'){
                        this.menuList[index].children[k].sel='Y'
                    }else if (oper==='I'){
                        this.menuList[index].children[k].ins='Y'
                    }else if (oper==='U'){
                        this.menuList[index].children[k].upd='Y'
                    }else if (oper==='D'){
                        this.menuList[index].children[k].del='Y'
                    }else if (oper==='C'){
                        this.menuList[index].children[k].cfm='Y'
                    }else if (oper==='O'){
                        this.menuList[index].children[k].oth='Y'
                    }
                }
            }else{
                for (let k=0;k<this.menuList[index].children.length;k++){
                    if (oper==='S'){
                        this.menuList[index].children[k].sel='N'
                    }else if (oper==='I'){
                        this.menuList[index].children[k].ins='N'
                    }else if (oper==='U'){
                        this.menuList[index].children[k].upd='N'
                    }else if (oper==='D'){
                        this.menuList[index].children[k].del='N'
                    }else if (oper==='C'){
                        this.menuList[index].children[k].cfm='N'
                    }else if (oper==='O'){
                        this.menuList[index].children[k].oth='N'
                    }
                }
            }
        },
        //一级菜单点击
        menuClick(index,code){
            this.listIndex =index
            this.menuBool=!this.menuBool
            if (this.openName.indexOf(code)===-1){
                this.openName.push(code)
            }else{
                this.openName.splice(this.openName.indexOf(code),1)
            }
        },
        //模块表单菜单
        getMenuList(){
            this.$axios({
                method:'post',
                url: this.$store.state.baseUrl+'auth/login/getUserMenu' ,
                data:{p_key:'isfront', p_value:'N' ,p_key2:'ismem', p_value2:'N'},
            }).then(res =>{
                this.menuList =res.data
                //默认全展开
                for (let k=0;k<res.data.length;k++){
                    this.openName.push(res.data[k].code)
                } 
            })
        },
        //会员中心菜单
        getMemList(){
            this.$axios({
                method:'post',
                url: this.$store.state.baseUrl+'auth/login/getUserMenu' ,
                data:{ p_key:'ISMEM',p_value:'Y',p_cond:' and ismem=\'N\'' },
            }).then(res =>{
                this.memberList =res.data  
                //默认全展开
                for (let k=0;k<res.data.length;k++){
                    this.openMem.push(res.data[k].code)
                } 
            })
        },
        memClick(index,code){
            this.memIndex =index
            this.memBool=!this.memBool
            if (this.openMem.indexOf(code)===-1){
                this.openMem.push(code)
            }else{
                this.openMem.splice(this.openMem.indexOf(code),1)
            }
        },
        //清空用户权限
        clearRec(){
            if (!this.rowIdseq  ){
                this.promptWin=true
                this.prompt='请选择用户'
            }else {
                this.confirmWin =true
                this.cfmprompt='你确定要清空此用户：'+this.formCol['USERNM']+' 权限吗?'
                this.cfmVal ='clear'
            }
        },
        //复制 用户或角色
        copy_ok(){
            if (!this.cpuser  ){
                this.promptWin=true
                this.prompt='请选择复制参考用户'
            }else {
                this.cfmprompt='你确定参考用户权限复制到此用户：'+this.formCol['USERNO']+' 吗?'
                this.confirmWin=true
                this.cfmVal='copy' 
            }
        },
        addClnt(){
            this.cltWin =true
            this.clntno='' 
        },
        addSup(){
            this.supWin =true
            this.supno='' 
        },
        //角色 新增、修改、删除、行记录选择
        addRole(){
            this.roleModal =true
            this.rowRoleCol ={}
        },
        editRole(){
            this.roleModal =true
        },
        delRole(){
            if (this.roleindex ==='-1'){
                this.promptWin=true
                this.prompt='请选择一行角色记录'
            }else{
                this.$confirm('你确定要删除此行记录吗','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消',
                    customClass:'messageBox',
                    showClose:false,
                    type:'info',
                }).then(()=>{
                    //发起删除请求
                    var vm=this;
                    this.$axios({
                        method: 'post',
                        url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                        data: {p_key:'idseq',p_value:vm.roleindex,p_table:'sys_role'},
                    }).then(res=>{      
                        if(res.data.code ==='200')  {      
                            vm.getRoleData()     
                        }else{
                            this.promptWin=true
                            this.prompt=res.data.message
                        }        
                    }) 
                }).catch(()=>{})
            }
        },
        roleClick(row ){
             this.rowRoleCol = JSON.parse(JSON.stringify(row))
             this.roleindex =row.IDSEQ
             this.getModData( row.IDSEQ )
             this.getMemData(row.IDSEQ)
 
        },
        //角色保存
        role_ok(){
            this.$refs['roleForm'].validate((valid)=>{
                if (valid){
                    var vm =this
                    this.$axios({
                        method: 'post',
                        url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+vm.v_username+'&password='+vm.v_password,
                        data: {p_json:JSON.stringify(this.rowRoleCol), p_table:'sys_role' },
                    }).then(res=>{      
                        if(res.data.result ==='OK')  {          
                            vm.roleModal =false   
                            vm.getRoleData()
                        }else{
                            this.promptWin=true
                            this.prompt=res.data.result
                        }        
                    }) 
                }
            })
        },
        //获取角色数据
        getRoleData(){
            var vm=this
            getTabColOrData(this.v_likeurl,this.v_username,this.v_password,'v_sys_role','','','','','','','').then((res) => {              
                vm.roleData  =res.data.result 
                if(res.data.result.length>0){ 
                    if (vm.roleindex==='-1'){ 
                        vm.roleindex =res.data.result[0].IDSEQ    
                    }            
                    this.getModData(vm.roleindex)
                    this.getMemData(vm.roleindex)    
                }    
            });            
        },
        //获取角色表字段  v-enter-number
        getRoleCol(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','19','fty','HEAD4','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    if (res.data[k].THIDE==='N') { // 
                        vm.roleCol.push({ //向数组的开头 添加序列号
                            title: res.data[k].title,
                            key: res.data[k].key,
                            align: res.data[k].align,
                            width: res.data[k].width,   
                            disabled:res.data[k].DISABLED,                   
                            fhide:res.data[k].FHIDE,
                        })
                    }
                }
            });
        },
        //角色权限保存
        user_ok(){
            let vm =this
            if(!this.rowIdseq){
                this.promptWin=true
                this.prompt='请选择用户记录'
            }else{
                this.$confirm('你确定要分配选中角色到用户：'+this.formCol['USERNO']+' 吗','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消',
                    customClass:'messageBox',
                    showClose:false,
                    type:'info',
                }).then(()=>{
                    this.$axios({
                        method:'post',
                        url: this.$store.state.baseUrl+'user/updateRec?username='+this.v_username+'&password='+this.v_password ,
                        data:{p_key:'idseq', p_value:this.rowIdseq,p_table:'sys_user',p_cond:' set roleid=\''+this.checkList.join()+'\''  },
                    }).then(res =>{
                        this.userModal =false
                        //复制角色权限
                        for(let k=0; k<this.checkList.length;k++){
                            this.$axios({
                                method: 'post',
                                url:vm.$store.state.baseUrl+'user/copyUserPriv?username='+vm.v_username+'&password='+vm.v_password,
                                data: {p_key:'p_fridseq',p_value:this.checkList[k],p_key2:'p_toidseq',p_value2:vm.rowIdseq,p_action:'copy'},
                            }).then(res=>{      
                                if(res.data.code !=='200')  {      
                                    this.promptWin=true
                                    this.prompt=res.data.message
                                }        
                            }) 
                        }
                        this.getFormData('','','','','','',' ORDER BY ADDDTTM DESC')
                        
                    })  
                }).catch(()=>{})
            }
 
        },
        //标签切换时
        tabClick(){
            if (this.tabName==='user'){
                this.modFormNm='自定义权限'
                this.getModData(this.rowIdseq)
                this.getMemData(this.rowIdseq)
            }else{ 
                this.modFormNm='角色权限'
                this.getRoleData()
            }
        } ,
        //全选功能
 
        //table row click
        elrowClick(row ,column,event){
            this.rowCol =row
        },
        //获取模块表单数据
        getModData(idseq){
            this.$axios({
                method:'post',
                url: this.$store.state.baseUrl+'auth/login/getUserMenu' ,
                data:{p_key:'isfront', p_value:'N',p_key2:'ismem', p_value2:'N',p_idseq:idseq  },
            }).then(res =>{
                this.menuList =res.data
            })           
        },
        //获取会员表单权限
        getMemData(idseq){
            this.$axios({
                method:'post',
                url: this.$store.state.baseUrl+'auth/login/getMemMenu' ,
                data:{p_key:'ISMEM', p_value:'Y',p_cond:' and ismem=\'N\'',p_idseq:idseq  },
            }).then(res =>{
                this.memberList =res.data
            })           
        },
 
        // 选择用户 时赋值给 其它字段
        handleChange(value){  
            if (value){     
 
                this.formCol['USERNM'] =filterArrObj('EMPNO',value ,this.staffArr)['USERNM']
                this.formCol['EMPTYPENM'] =filterArrObj('EMPNO',value ,this.staffArr)['EMPTYPENM']
                this.formCol['MAILADDR'] =filterArrObj('EMPNO',value ,this.staffArr)['MAILADDR']
                this.formCol['DEPTNO'] =filterArrObj('EMPNO',value ,this.staffArr)['DEPTNO']
            }
        }, 
        //保存权限
        saveRec(){            
            //发起保存请求   
            var vm=this,v_action='',v_idseq='',v_user='',gpno_=''
            if( this.tabName==='user'){ //用户tab   
                v_user =vm.formCol['USERNO']   
                v_action='USER'
                v_idseq =vm.rowIdseq
            }else{
                v_user =vm.roleindex
                v_action ='ROLE'
                v_idseq =vm.roleindex
            }
            if (!v_user){
                if (v_action==='USER'){
                    this.promptWin=true
                    this.prompt='请选择用户记录'
                }else if (v_action==='ROLE'){
                    this.promptWin=true
                    this.prompt='请选择角色记录'
                }
            }else{
                //后台中心权限
                for(let k=0;k<this.menuList.length;k++){
                    for(let m=0;m<this.menuList[k].children.length;m++){
                        let rowCol=this.menuList[k].children[m]
                        gpno_ =''
                        if (rowCol.sel==='Y' ){
                            if (gpno_===''){gpno_='sel'}else{gpno_=gpno_+','+'sel'}
                        }
                        if (rowCol.ins==='Y' ){
                            if (gpno_===''){gpno_='add'}else{gpno_=gpno_+','+'add'}
                        }if (rowCol.upd==='Y' ){
                            if (gpno_===''){gpno_='upd'}else{gpno_=gpno_+','+'upd'}
                        }if (rowCol.del==='Y' ){
                            if (gpno_===''){gpno_='del'}else{gpno_=gpno_+','+'del'}
                        }if (rowCol.cfm==='Y' ){
                            if (gpno_===''){gpno_='cfm'}else{gpno_=gpno_+','+'cfm'}
                        }if (rowCol.oth==='Y' ){
                            if (gpno_===''){gpno_='oth'}else{gpno_=gpno_+','+'oth'}
                        }
                        //console.log('gpno:'+gpno_+ v_action+';'+v_user)
                        this.$axios({  //P_JSON  ,P_TABLE ,P_USER ,P_OPTION ,P_OPER ,P_ACTION 
                            method: 'post',
                            url:vm.$store.state.baseUrl+'user/saveUserModForm?username='+vm.v_username+'&password='+vm.v_password,
                            data: {p_json:JSON.stringify(rowCol ), p_table:'SYS_USER_PRIV',p_user:v_user,p_action:v_action,gpno:gpno_,p_oper:vm.v_username},
                        }).then(res=>{     
                            //if (res.data.code=='200'){    
                                vm.getModData( v_idseq )  
                            // }else{
                            //     this.promptWin=true
                            //     this.prompt=res.data.result
                            // }
                        }) 
                    }
                }
                
                // 会员中心权限
                let memArr=[] 
                for(let k=0;k<this.memberList.length;k++){
                    for(let m=0;m<this.memberList[k].children.length;m++){
                        let rowCol=this.memberList[k].children[m]
                        memArr.push(rowCol)
                    }
                }
                this.$axios({  
                    method: 'post',
                    url:vm.$store.state.baseUrl+'user/batMemSave?username='+vm.v_username+'&password='+vm.v_password,
                    data: {p_json:JSON.stringify(memArr ), p_table:'USER_MEM_PRIV',p_user:v_user,p_action:v_action, p_oper:vm.v_username},
                }).then(res=>{   
                    if (res.data.code==='200'){   
                        this.getMemData(v_idseq)  
                        this.$Message.info('已保存') 
                    } else{
                        this.promptWin=true
                        this.prompt=res.data.result
                    }
                })     
            }
      
        },
 
        //复制记录
        copyRec(){
            if(!this.rowIdseq){
                this.promptWin=true
                this.prompt='请选择一用户记录'
            }else{
                this.cpModal =true
            }
        },
        //分配角色
        addRec(){
            this.userModal =true
            if (this.formCol.ROLEID){
                this.checkList =this.formCol.ROLEID.split(',')
            }else{
                this.checkList =[]
            }
        },
        //查询选择器事件
        elchange(value){
            let obj ={}
            obj =this.userCol.find(item=>{
                return item.key===value
            })
            if (obj)
            this.input_hold='请输入'+obj.title+' 的值'
        },
        //查询表格数据
        qryTabData(){
            let fldno= !this.selparam?'idseq':this.selparam
 
            this.getFormData('','','','','','',' and '+fldno+' like \'%'+this.textparam+'%\' order by adddttm desc')
        },
 
        //表格行点击事件
        rowClick(row ) {
            this.formCol = JSON.parse(JSON.stringify(row))
            this.rowIdseq =row.IDSEQ
            this.userno=row.USERNO
            this.getModData( row.IDSEQ )
            this.getMemData(row.IDSEQ)
            this.stfgChange('')
            this.stfgClnt('')
        },
        //获取用户表单数据
        getFormData(v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_){
            var vm=this
            this.loading =true
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_sys_user',v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_).then((res) => {              
                vm.userData =res.data   
                vm.loading =false 
                if(res.data.length>0){  
                    if (vm.rowIdseq==='-1'){                
                        vm.rowIdseq =res.data[0].IDSEQ                       
                    }
                    vm.userno=res.data[0].USERNO
                    this.formCol =res.data[0]
                    this.getModData( vm.rowIdseq ) 
                    this.getMemData(vm.rowIdseq)
                    vm.stfgChange(vm.rowIdseq)
                    vm.stfgClnt(vm.rowIdseq)
                } else{
                    this.getModData( '-1') 
                    this.getMemData('-1')
  
                }
                vm.$nextTick(()=>{
                    this.$refs.refuser.doLayout()
                })       
            });            
        },
        //获取表列头字段  v-enter-number
        getTabCol(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','19','fty','HEAD' ,'','',' order by sortby,lstseq').then((res) => {
                this.userFormCol =res.data
                for (let k=0; k<res.data.length; k++){
                    if (res.data[k].THIDE==='N') { // 
                        vm.userCol.push({ //向数组的开头 添加序列号
                            title: res.data[k].title,
                            key: res.data[k].key,
                            edcss:res.data[k].EDCSS,
                            align: res.data[k].align,
                            width: res.data[k].width,                        
                        })
                    }
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','19','fty','HEAD5','','',' order by sortby,lstseq').then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.supCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,
                        thide: res.data[k].THIDE,
                        fhide: res.data[k].FHIDE,
                        sortable: res.data[k].sortable?true:false,
                    })
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','19','fty','HEAD6','','',' order by sortby,lstseq').then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.cltCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,
                        thide: res.data[k].THIDE,
                        fhide: res.data[k].FHIDE,
                        sortable: res.data[k].sortable?true:false,
                    })
                }
            })
        },
 
    }
}
 
</script>
<style scoped  lang="less">
    .deptdiv{
       border:1px solid #e9e9e9;
       height:670px;
       overflow:auto;
       width:350px;       
   }
   .flddiv{
       border:1px solid #e9e9e9;
       height:400px;
       overflow:auto;
       width:350px;       
   }
   .btn{
       width:80%;
       margin-top:100px;
       height:40px;
       margin-left:5px;
       margin-right:5px;
   }
   .dashbtn{
       height:35px;
       width:90px;
 
   }
.selinput{
    width:300px;
    margin-top:2px;
    margin-left:10px;
}
.colwidth{
    width:140px;
}
.colwidth2{
    width:642px;
}
.oneMenu{
        line-height:35px;
        text-align:left;
        padding-left:20px;
        font-size:14px;
        font-weight:550;
        color:#5cadff;
        background-color: #dcdee2;  
        display:flex;
    }
    .oneMenu:hover{
        cursor: pointer;
        color:#19be6b;
    }
    .twoMenu{
        display:flex;
        flex-direction:column;
        line-height:40px;
        background:white;
        padding-left:35px;
    }
    .twoMenu:hover{
        cursor: pointer;
        color:#19be6b;
        background:#e8eaec;
    }
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
</style>
<style lang="less">
    .el-table {
        th {
            padding: 0 ;
        }
        thead{
            height: 15px !important;
            color:rgb(128,128, 128);
            font-size:12px;
        }
        td {
            padding: 5px ;
            font-size:11px;
        }   
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
    .el-dialog__body{
        padding: 1px 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn { margin-top:0px;  }
    .el-dialog__headerbtn i { background:white;font-size: 18px; }
    .messageBox .el-message-box__content{
        border-top: 1px solid #EBEEF5;
        height: 50px;
    }
    .el-message-box__message p{
        line-height: 25px;
    }
    .messageBox .el-message-box__btns{
        padding: 20px 15px 0;
    }
    .messageBox .el-message-box__header{
        background:#409eff;
    }
    .messageBox .el-message-box__title{
        color:white ;
        font-size:14px;
    }
</style>
