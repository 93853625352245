<template>
    <div  style="margin-top:2px;">
        <div style="margin-bottom:5px;">
            <Button type="dashed" icon="md-close"   @click="tree_Cancel" style="margin-right:25px;">取消</Button>
            <Button type="dashed"  icon="md-checkmark" @click="tree_Ok" >确定选择</Button>
        </div>            
        <el-tree :data="treeData" 
                    :props="defaultProps" 
                    @node-click="handleNodeClick" 
                    node-key="id"                       
                    :style="{ overflow:'auto' }"
                    highlight-current 
                    :filter-node-method="filterNode"
                    ref="eltree"
                    :expand-on-click-node="false"
        >
            <template v-slot="{node,data}">                                     
                <span v-if="data.id ==0 "  class="el-icon-notebook-1 nodeRoot" >
                    <span class="nodeLabel">{{ node.label}} </span>
                </span>                  
                <span v-else class="el-icon-folder" > 
                    <span class="nodeLabel">{{ node.label}} </span>
                </span>
            </template>
        </el-tree>  
    </div> 
</template>

<script> 
    export default {
        data(){
            return {
                treeData:[] ,//  树数据
                filterText: '', 
                defaultProps: {  //el-tree 默认字段属性
                    children: 'children',
                    id:'id',
                    icon:'icon',
                    parentid:'',
                    label: 'label'
                },
                treeid :'0',               
                nodeLabel:'',
                v_url:this.$store.state.queryUrl , //api请求路径
                v_username:this.$cookies.get('v_username'), //api 请求用户
                v_password:this.$cookies.get('v_password') ,//api 请求密码   
                //定义点击次数,默认0次
                clickCount: 0,
            }
        },
        created(){
            //初始化
            this.getData();
        },
        mounted(){

        },
        computed:{
            scrollerHeight: function() {
                return (window.innerHeight - 200) + 'px';
            },
            
        },
        watch:{
            filterText(val) {
                this.$refs.eltree.filter(val);
            }
        },
        props:{
            //   接受父组件传的值
            param_idseq:{
                type:String,         
            }
        },
        methods:{
            tree_Ok(){
                if (!this.nodeLabel){
                    this.$Message.error('请选择一节点')
                }else{
                    this.$emit('child-ok',this.nodeLabel);
                }
            },
            tree_Cancel(){
                this.$emit('child-exit' );
            },
 
            //节点过滤
            filterNode(value, data) {
                if (!value) return true;
                return data.label.indexOf(value) !== -1;
            },
            //节点点击
            handleNodeClick(data){
                //单击事件处理
                this.nodeLabel=data.label;            
                this.treeid =data.id                 
                //记录点击次数
                this.clickCount++
                //单次点击次数超过2次不作处理,直接返回,也可以拓展成多击事件
                if (this.treeClickCount >= 2) {
                    return;
                }
                //计时器,计算300毫秒为单位,可自行修改
                setTimeout(() => {
                    if (this.clickCount === 1) {
                        //把次数归零
                        this.clickCount = 0;                     
                    } else if (this.clickCount > 1) {
                        //把次数归零
                        this.clickCount = 0;
                        //双击事件
                        this.$emit('child-ok',this.nodeLabel);
                    }
                }, 300);

            },
 
            // 获取 树形控件数据
            getData() {  
                var vm=this;
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/getElTreeData?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_table:'V_HR_FRAME'}
                }).then(function(res){                  
                    vm.treeData =res.data;  
                    if(res.data.length > 0 ){
                        //'nextTick()' 下次dom更新时触发回调函数
                        vm.$nextTick().then(() =>{
                            //使用setCurrentKey方法的时候必须将其放在nextTick中,要等数据更新渲染完再调用,否则找不到对应元素
                            vm.$refs.eltree.setCurrentKey(vm.treeid)
                        })
                    }               
                }).catch(function(err){
                    if (err.response){
                        console.log(err.response.data+':'+err.response.status+err.response.headers)
                    }
                })                            
            },
        }
    }
</script>

 
