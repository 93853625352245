<template>
    <div class="main_back"> 
        <div style="display:flex;justify-content:center; ">
            <span style="font-size:1.2em;color:#D3D3D3;margin-top:7px;margin-right:7px;">请选择产品大类</span>
            <el-select placeholder="请选择产品大类" size="small" class="formitem" type="text" v-model="regPrd.findTy"  @change="qryPrdType">
                <div style="display:flex;flex-wrap:wrap; width:620px; " >
                    <el-option
                        v-for="item in firstType"
                        :key="item.SUBNO"
                        :label="item.CNAME"
                        :value="item.SUBNO"> 
                        <div  style="width:140px;">  
                            <span >{{item.CNAME}}</span>
                        </div>                                      
                    </el-option>
                </div>               
            </el-select>                                   
        </div>
        <div style="display:flex;justify-content:center;margin-top:10px;" >
            <div style="border :1px solid #e9e9e9;height:500px;overflow:auto;width:650px;" >
                <div style="border-bottom: 2px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;"
                    v-for="(item ,index) in prdTypeGp" :key="index">
                        <a @click="handleCheckAll(item.value)"><Icon type="md-checkmark" style="padding-left:10px" size="14"></Icon> </a>
                        <a @click="handleUnCheckAll(item.value)"><Icon type="md-close" style="padding-left:10px;padding-right:5px;" size="14"></Icon> </a>
                        <span style="color:#BC8F8F;font-size:1.1em"> {{item.label}}</span>
                        <CheckboxGroup v-model="checkAllGroup" @on-change="handleChange">
                            <Checkbox v-for="(data ) in item.options" :key="data.value" 
                                      :label="data.label" style="width:190px;margin-left:10px;">
                            </Checkbox>
                        </CheckboxGroup>
                    
                </div> 
            </div>
            <div style="display:flex;flex-direction:column;" >
                <div style="border:1px solid #e9e9e9;height:470px;overflow:auto;width:270px; margin-left:20px;" >
                    <div style="display:flex;justify-content:space-between;flex-wrap:wrap;width:210px">                 
                    <div v-for="(item,index) in checkAllGroup" :key="index" style="width:210px;margin-left:5px;margin-top:6px;">
                        <a @click="removeItem(index)"><Icon type="md-close"></Icon></a>
                        <span style="width:180px;font-size:14px;"> {{item}} </span>
                    </div>
                    </div>                  
                </div>
                <div style='background:#EBEEF5;height:31px;width:310; margin-left:20px; '>
                    你已选择【<span class="fontsize">{{checkAllGroup.length}}</span>】类别
                    <Button type="info" style="margin-left:20px;" @click="goAhead">确定</Button> 
                    <Button type="info" style="margin-left:20px;" @click="exitBtn">取消</Button>   
                </div>
            </div>
 
        </div>
    </div>

</template>
<script>
 
import { getTabColOrData ,getBaseData} from '../../api/user';
export default ({
    data () {
        return {
            indeterminate: true,
            checkAllGroup: [],
            prdTypeGp:[], //生成el-checkbox 组别数据
            showheader:false,
            bool:true,
            transData:[],
            transval:[] ,//穿梭框选中的值 ，目标列表中的数据项会同步到绑定至v-model变量，值为数据项的key所组成的数组
            firstType:[] ,//一级类别
            secondType:[],
            thirdType:[],
            columns1:[
                {
                    key:'CNAME',
                    title:'名称'
                },
                {
                    key:'SUBNO',
                    title:'',
                    width:50,
                    render:(h,params)=>{
                        return h("Icon" ,{
                            props: {
                                type: 'md-arrow-dropright'
                            },
                        } )
                    } 
                }
                 
            ],
            ruleValidate:{

            },
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            value:[], //级联选择器
            regPrd:{
                findTy:''
            },
            
        }
    },
    props:{
        //   接受父组件传的值
        param_idseq:{
            type:String,         
        }
    },
    created () { 
        //一级代码
        getBaseData(this.$store.state.nologinUrl, 'parentno','0','','','prd_category'," order by subno ").then(res=>{
            this.firstType=res.data.result
            for(var k=0 ;k<res.data.result.length; k++) {
                this.transData.push({
                    key: res.data.result[k].SUBNO,
                    label: res.data.result[k].CNAME,
                    //disabled: k % 4 === 0
                });
            }
        }) 
    },
    mounted () {
      this.checkAllGroup =[]
    },
    methods: {
        removeItem(index){
            this.checkAllGroup.splice(index,1)
        },
        qryPrdType(){
            getBaseData(this.$store.state.baseUrl+'auth/login/getCheckGroup', 'parentno',this.regPrd.findTy,'','','prd_category'," order by subno ").then(res=>{
                this.prdTypeGp=res.data
            })
        },
 
        //前往验证
        goAhead(){
            var vm=this
            if (this.checkAllGroup.length===0 ){
                this.$Message.error('请选择类别'); 
            }else{
                let type_=''
                for(let k=0 ;k<this.checkAllGroup.length;k++){
                    if (k===0){
                        type_=this.checkAllGroup[k]
                    }else{
                        type_=type_+','+this.checkAllGroup[k]
                    }
                }      
                this.$emit('child-ok',type_);  //调用父组件的方法                             
            }
        },
        exitBtn(){
            this.$emit('child-exit' )
        },
        //全选
        handleCheckAll (value) {
            getBaseData(this.$store.state.nologinUrl, 'parentno',value,'','','prd_category'," order by subno ").then(res=>{
               res.data.result.some(item=>{
                   if (this.checkAllGroup.indexOf(item.CNAME) ===-1){
                       this.checkAllGroup.push(item.CNAME)
                   }
               })
            }) 
        },
        //全不选
        handleUnCheckAll (value) {
            getBaseData(this.$store.state.nologinUrl, 'parentno',value,'','','prd_category'," order by subno ").then(res=>{
               res.data.result.some(item=>{
                   let index =this.checkAllGroup.indexOf(item.CNAME)
                   if (index >-1){
                       this.checkAllGroup.splice(index,1)
                   }
               })
            }) 
        },
        handleChange () {
            //console.log( this.checkAllGroup )            
        }
    }
})
</script>
<style scoped lang="less">
.main_back{ 
    margin:10px auto;  
    width:99%;
    border:1px solid #fff;
}
.main_back .hor{
    text-align:center;
    background:#F0F8FF;
}
.formitem{
    font-size:14px;
    font-weight:600;
    margin-bottom:20px;
    width:620px;
    height:20px;
}
.fontsize{
    font-size:14px;
    font-weight:600;
}
.elbutton{
    margin-top:5px;
    width:400px;
    margin-left:90px;
    height:40px;
    margin-bottom:10px;
} 
.selsectbox  {
 
     height:10px !important;
     width:90px;
     border-radius: 1px;
     color: red;
     font-size: 14px !important;
     font-weight: 550;
  }
  .container{
      display:flex;justify-content:space-between;margin-top:10px;
  }
 

</style>
<style>
     .el-transfer-panel{
        width:400px;       
    }
    .el-transfer-panel__list.is-filterable{
        width:2220px !important;
    }
</style>
