<template>
    <div  style="background:white;height:100%">
          
        <el-tree :data="treeData" 
                    :props="defaultProps" 
                    @node-click="handleNodeClick" 
                    node-key="id"                       
                    :style="{ overflow:'auto',background:'#fff'}"
                    v-loading="loadingtree"
                    element-loading-text="加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(255, 251, 255, 0.8)"
                    highlight-current 
                    :filter-node-method="filterNode"
                    ref="eltree"
                    default-expand-all
                    :expand-on-click-node="false"
        >
            <template v-slot="{node,data}">                                     
                <span v-if="data.id ==0 "  class="el-icon-notebook-1 nodeRoot" >
                    <span class="nodeLabel">{{ node.label}} </span>
                </span>                  
                <span v-else class="el-icon-folder" > 
                    <span  v-if="data.id ==45803">{{ node.label}}【<span style="color:red">{{regNum}}</span>】</span>
                    <span v-else class="nodeLabel">{{ node.label}} </span>
                </span>
            </template>
        </el-tree>  
    </div> 
</template>

<script> 
    import { getTabColOrData  } from '../../api/user';
    export default {
        data(){
            return {
                treeData:[] ,//  树数据
                filterText: '', 
                defaultProps: {  //el-tree 默认字段属性
                    children: 'children',
                    id:'id',
                    icon:'icon',
                    parentid:'',
                    label: 'label'
                },
                treeid :'0',   
                loadingtree:false,            
                nodeLabel:'',
                v_url:this.$store.state.queryUrl , //api请求路径
                v_username:this.$cookies.get('v_username'), //api 请求用户
                v_password:this.$cookies.get('v_password') ,//api 请求密码   
                //定义点击次数,默认0次
                clickCount: 0,
                regNum:'0',
            }
        },
        created(){
            
        },
        mounted(){
            //初始化
            this.getData();
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_hr_emp','deptid','45803','','','','','').then((res) => {
                this.regNum =res.data.length //注册未审核
            })
        },
        computed:{
            scrollerHeight: function() {
                return (window.innerHeight - 200) + 'px';
            },
            
        },
        watch:{
            filterText(val) {
                this.$refs.eltree.filter(val);
            }
        },
        props:{
            //   接受父组件传的值
            param_idseq:{
                type:String,         
            }
        },
        methods:{
            
            //节点过滤
            filterNode(value, data) {
                if (!value) return true;
                return data.label.indexOf(value) !== -1;
            },
            //节点点击
            handleNodeClick(data){
                //单击事件处理
                this.nodeLabel=data.label;            
                this.treeid =data.id                 
                this.$emit('child-nodeClick',this.treeid); 
            },
 
            // 获取 树形控件数据
            getData() {  
                var vm=this;
                this.loadingtree =true
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/getElTreeData?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_table:'V_HR_FRAME'}
                }).then(function(res){                  
                    vm.treeData =res.data;  
                    if(res.data.length > 0 ){
                        //'nextTick()' 下次dom更新时触发回调函数
                        vm.$nextTick().then(() =>{
                            //使用setCurrentKey方法的时候必须将其放在nextTick中,要等数据更新渲染完再调用,否则找不到对应元素
                            vm.$refs.eltree.setCurrentKey(vm.treeid)
                        })
                    }    
                    vm.loadingtree =false           
                }).catch(function(err){
                    if (err.response){
                        console.log(err.response.data+':'+err.response.status+err.response.headers)
                    }
                })                            
            },
        }
    }
</script>
 
 
