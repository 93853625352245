<template>
    <!--用于后台文档选择，由于cookie值的用户不同，所以与前端分离-->
    <div class="wraper" > 
        <el-container >  
            <el-aside width="250px" >
                <el-tree :data="treeData" 
                         :props="defaultProps" 
                         @node-click="handleNodeClick" 
                         node-key="id"      
                         :default-expand-all="true"    
                         v-loading="loadingtree"
                         element-loading-text="加载中"
                         element-loading-spinner="el-icon-loading"
                         element-loading-background="rgba(255, 251, 255, 0.8)"               
                         :style="{background:'#f8f8f9',overflow:'auto',height: '555px'}"
                         highlight-current
                         ref="eltree"
                         :expand-on-click-node="false">
                    <template v-slot="{node,data}">                                     
                        <span v-if="data.id ==0 "  >
                          <span  style="color:#F56C6C;font-size:14px;"><i class="iconfont icon-shujuku" style="color:#F56C6C;font-size:18px;"></i> {{ node.label}} </span>
                        </span>                  
                        <span v-else  > 
                            <span style="color:#2b85e4;font-size:12px;"><i class="iconfont icon-wenjianmulu" style="margin-right:5px;"></i>{{ node.label}} </span>
                        </span>
                    </template>
                </el-tree>
            </el-aside>
            <el-main  > 
                <div  style="display:flex; " >      
                    <div style="width:640px;">
                        <div style="font-size:12px; margin-bottom:5px;" >
                            <el-breadcrumb separator-class="el-icon-arrow-right">
                                <el-breadcrumb-item v-for="(item,index) in pathArr" :key="index" >
                                    <span style="color:#67C23A"  >{{item.name}}</span>
                                </el-breadcrumb-item>
                            </el-breadcrumb>
                        </div> 
                        <div style="border-top:1px solid #ccc;border-left:1px solid #ccc;border-right:1px solid #ccc;">
                            <div style="line-height:30px; height:40px;padding-top:5px;">
                                <i class="iconfont icon-xinzeng" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                                <el-link @click="addFolder" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;">新增子目录</span></el-link>
                                <Upload
                                    ref="upload"
                                    name="upfile"
                                    :data="uploadData"
                                    :show-upload-list="false"
                                    :before-upload="beforeImgFile"
                                    :on-success="successImgFile"
                                    :accept="Accept"                           
                                    :format="['jpg','jpeg','png','pdf']"
                                    :max-size="2048"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :action="uploadUrl"
                                    style="display: inline-block;width:70px;margin-right:10px;vertical-align:middle; ">                           
                                    <div style="display:flex;width:140px;margin-top:-2px;margin-right:15px;">
                                        <i class="iconfont icon-shangchuan1" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:20px;"></i>
                                        <el-link :underline="false"  >
                                        <span style="font-size:13px;margin-right:15px;color:#E6A23C">上传文件</span>
                                        </el-link>  
                                    </div>                                                      
                                </Upload>
                                <span   style="margin-top:-2px;margin-left:40px;">文件搜索</span>
                                <el-input @change="fileSearch" v-model="srchFile" placeholder="请输入搜索文件名" style="width: 140px;margin-left:5px;" size="mini">
                                    <!-- <template slot="append" ><i class="el-icon-search"></i></template> -->
                                </el-input> 
                                <span   style="margin-top:-2px;margin-left:20px;margin-right:5px;">排序</span>
                                <Select v-model="fileSort" style="width:120px;" size="small" @on-change="fileSortBy('')">
                                    <Option   v-for="item in sortList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                                </Select>
                                <el-link :underline="false" v-show="sortFlag " @click="ascSort">
                                    <i class="iconfont icon-paixu-shengxu" style="color:#409EFF;font-size:14px; margin-left:10px;"></i>
                                </el-link>
                                <el-link :underline="false" v-show="!sortFlag " @click="descSort">
                                    <i class="iconfont icon-paixu-jiangxu" style="color:#409EFF;font-size:14px; margin-left:10px;"></i>
                                </el-link>
                            </div>
        
                        </div>
                        <div :style="{'border':'1px solid #ccc','height':'430px', 'overflow-y':'auto'}">    
                            <!-- 目录集 -->
                            <div style="display:flex;flex-wrap:wrap">
                                <div v-for="(item,index) in dirList" :key="index" style="display:flex;flex-direction:column">
                                    <div class="dircss" >
                                        <el-link :underline="false" @click="dirClick(item.CODE,item.CNAME,item.IDSEQ,item.ICON,item.PARENTID)" style="padding-top:10px; ">
                                            <i class="iconfont icon-select-file" style="font-size:40px;"></i>
                                        </el-link>
                                    </div>
                                    <div :title="item.CNAME" class="divtext">{{item.CNAME}}</div>
                                </div>
                            </div>
                            <!-- 文件集 -->
                            <div style="display:flex;flex-wrap:wrap">
                                <div v-for="(item,index) in fileList" :key="index" style="display:flex;flex-direction:column">
                                    <div :class="fileArr.indexOf(item.PKSEQ)>-1?'dircss2':'dircss'"  >
                                        <el-link :underline="false" @click.native="fileClick(index,item.PKSEQ,item.FILEPATH)">
                                            <span v-if="item.FILETY==='pdf'">
                                                <el-image   :src="pdfImg" fit="fill" style="width:88px; max-height:78px;" ></el-image>
                                                <el-link :underline="false" class="picsrch" @click.native="pdfPreview(item.FILEPATH)">      
                                                        <i class="el-icon-search" style="font-size:20px;font-weight:600;background:#fff;color:#606266;z-index:9"></i>
                                                    </el-link> 
                                                <div class="selected" v-if="fileArr.indexOf(item.PKSEQ)>-1"></div> 
                                            </span>
                                            <span v-else-if="item.FILETY==='mp4'">
                                                <el-image   :src="mp4Img" fit="scale-down" style="width:80px; max-height:70px;" ></el-image>
                                                <el-link :underline="false" class="picsrch" @click.native="mp4Preview(item.FILEPATH)">      
                                                        <i class="el-icon-search" style="font-size:20px;font-weight:600;background:#fff;color:#606266;z-index:9"></i>
                                                    </el-link> 
                                                <div class="selected" v-if="fileArr.indexOf(item.PKSEQ)>-1"></div> 
                                            </span>
                                            <span v-else>
                                                <el-image   :src="item.FILEPATH" fit="fill" style="width:88px; max-height:78px;" ></el-image>
                                                <el-link :underline="false" class="picsrch"> 
                                                    <el-tooltip  effect="light"  placement="right" style="margin:4px;cursor:pointer" popper-class="atooltip">
                                                        <i class="el-icon-search" style="font-size:20px;font-weight:600;background:#fff;color:#606266;z-index:99"></i>
                                                        <template slot="content" >
                                                            <el-image   fit="scale-down" style="width:300px;height:300px; text-align:center; " :src="item.FILEPATH"></el-image>        
                                                        </template>
                                                    </el-tooltip>
                                                </el-link>
                                                <div class="selected" v-if="fileArr.indexOf(item.PKSEQ)>-1"></div>
                                            </span>
                                        </el-link>
                                        
                                    </div>
                                    <div :title="item.FILENM" class="divtext">{{item.FILENM}}</div>
                                </div>
                            </div>  
                        </div>          
                        <div class="pageNext">
                            <div style="display:flex;justify-content:center;align-items:center;">  
                                <Page :total="dataCount" :page-size="rowSize" show-total :current="current" @on-change="changePage"></Page>
                                <span style="color:#606266;margin:7px 10px;font-size:12px;">每页</span>
                                <el-select v-model="rowSize" size="small" style="width:70px;" @change="initPage">
                                        <el-option 
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                </el-select>
                                <span style="color:#606266;margin:7px 10px;font-size:12px;">跳至</span>
                                <el-input v-enter-number size="small" v-model="goPage" style="width:50px;"></el-input> 
                                <span style="color:#606266;margin:7px 5px;font-size:12px;">页</span>
                                <el-button @click="goNext" size="small" type="primary" style="width:50px;margin-left:15px;height:35px;">GO</el-button>
                            </div>
                        </div> 
                    </div>
                    <div style="width:120px;height:535px;display:flex;flex-direction:column; margin-left:20px; ">
                        <div style="height:55px; padding-top:30px;text-align:center;color:#ed4014">待添加 ( {{addqty}} / 10 )</div>
                        <div style="height:480px;overflow-y:auto;border :1px solid #ccc; text-align:center;display:flex;flex-direction:column"> 
                            <div v-for="(item,index) in addList" :key="index" class="picSel">
                                <el-image  fit="fill" style="width:80px;height:70px;margin:5px auto;" :src="filetype==='pdf'?pdfImg:filetype==='mp4'?mp4Img:item"></el-image> 
                                <el-link :underline="false" class="picdel" @click="selDel(item)">     
                                    <i class="el-icon-error" style="font-size:20px; background:#fff;color:red;z-index:999"></i>
                                </el-link>
                            </div> 
                        </div>
                    </div>
                </div>   
                <div style="height:40px;margin-top:10px; display:flex;justify-content:center">
                     <el-button @click="docCfm" size="small" type="primary" style="width:90px;margin-right:100px;height:35px;">确定</el-button>
                     <el-button @click="docExit" size="small" type="primary" plain style="width:90px;margin-left:15px;height:35px;">取消</el-button>
                </div>

            </el-main>
            <Modal v-model="mp4Model"   scrollable  width="450" :footer-hide="true">
                <div style="width:400px;height:250px;border:1px solid #e8eaec;overflow-y:hidden;margin:20px auto;">  
                    <video-player   class="video-player vjs-custom-skin" 
                                    :playsline="false" 
                                    :options="playerOptions">
                    </video-player>
                </div>
            </Modal>
            <Modal v-model="addModal"  :closable="false"  scrollable  width="380">
                <Form ref="addForm" :model="formCol" :label-width="100"   label-position="right" inline >
                    <FormItem label="上级目录编码"   >                                  
                        <Input type="text" disabled  v-model="formCol.PARENTNO"  style="width:200px;" clearable/>
                    </FormItem>
                    <FormItem label="上级目录名称"   >                                  
                        <Input type="text" disabled  v-model="formCol.PARENTNM"  style="width:200px;" clearable/>
                    </FormItem>
                    <FormItem label="本级目录编码"   >                                  
                        <Input type="text" disabled  v-model="formCol.CODE"  style="width:200px;" clearable/>
                    </FormItem>
                    <FormItem label="本级目录名称"   >                                  
                        <Input type="text"   v-model="formCol.CNAME"  style="width:200px;" clearable/>
                    </FormItem>
                </Form>                     
                <div slot="footer" style="height:24px;">
                    <Button type="primary" plain style="margin-right:25px;"  @click="addModal=false">取消</Button>
                    <Button type="primary"   @click="modal_ok">确定</Button>
                </div>
                <div slot="header" style="height:10px;line-height:10px">
                    <span style="font-weight:600;font-size:14px;color:#ccc">{{addtype==='create'?'新增目录':'修改目录'}}</span>
                </div>
            </Modal>    
        </el-container>  
    </div>  
</template>
<script>
 
import { paramBase,paramData ,getDate} from '../../api/Select'
//局部注册
export default {
    data () {
        return {
            loadingtree:false,
            mp4Model:false,
            addModal:false,
            treeCode:'',
            addqty:'0',
            filetype:'pic',
            addList:[],
            treeLabel:'',
            addtype:'create',
            formCol:{
                PARENTNO:'', //上级目录编码
                PARENTNM:'',//上级目录名称
                CODE:'', //本级目录编码
                CNAME:'', //本级目录名称
            },
            current:1,
            dataCount:0,
            srchFile:'',
            moveVal:'',
            rowSize:20,
            mp4Img:'/assets/basedict/video.png',
            pdfImg:'https://kreco.oss-accelerate.aliyuncs.com/manage/image/product/richie3898720211201160952.png',
            allData:[],
            fileArr:[], //点击后加入数组
            sortFlag:true,
            uploadData:{}, //上传数据
            uploadUrl:this.$store.state.baseUrl +'oss/fileUpload?username='+this.v_user+'&password='+this.v_pwd,
            Accept: "png,jpg,jpeg,mp4,pdf", //上传文件格式限制
            sortList: [
                {
                    value: 'time',
                    label: '按上传时间'
                },
                {
                    value: 'size',
                    label: '按文件大小'
                },
                {
                    value: 'name',
                    label: '按文件名称'
                }
            ],
            defaultProps: {  //el-tree 默认字段属性
                children: 'children',
                id:'id',
                icon:'icon',
                parentid:'parentid',
                idseq:'idseq',
                label: 'label'
            },
 
            pathName:'资源库',
            fileSort:'time',
            goPage:'',
            options: [{
                value: 10,
                label: '10'
                }, {
                value: 20,
                label: '20'
                }, {
                value: 30,
                label: '30'
                } 
            ],
            LANG:'', 
            lock:'N',
            treeid:'', // 
            idseq:'',
            fileName:'',
            filety:'',
            nodeLabel:'',
            parentid:'',
            pathArr:[],
            dirList:[], //目录集
            fileList:[], //文件集
            treeData:[] ,//  树数据    
            checkAll:'N',
            curIndex:'-1',
            disabledValues:['0'],
            pageSize:5, //证书每页显示数量
            v_user :this.$cookies.get('v_username'),
            v_pwd :this.$cookies.get('v_password'),
            playerOptions: {
                playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                autoplay: false, // 如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                    src: '' // url地址 /assets/authorize/tmp4.mp4
                }],
                poster: '', // 你的封面地址
                //width: '100%', // 播放器宽度
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true // 全屏按钮
                }
            },
        }
    },
    mounted () {   
        this.getPoint('0') //获得节点下的目录 

    },
    computed: {
 
    },
    components: {
  
    },
    created () {
        //左侧tree data
        this.getData('0')
        if(!this.filety){
            this.filety=' in (\'jpg\',\'png\',\'gif\',\'pdf\',\'mp4\')'
        }
    },
    watch: {
 
    },
    methods: {  
        // 文档确定和取消
        docCfm(){
            if (this.addList.length ==0){
                this.$alert('请选择图片', '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'info'
                })
            }else{
                this.$emit('doc-sure',this.addList,this.filetype) 
            }
        },
        docExit(){
            this.$emit('doc-exit')
        },
        //pdf 预览
        pdfPreview(fileUrl){
            //全屏显示
            let routeUrl = this.$router.resolve({
                path: '/fullPreview',
                query: {lang:this.LANG,fileUrl: fileUrl },
            });
            window.open(routeUrl.href, '_blank'); 
        },
        //mp4 预览
        mp4Preview(fileUrl){
            //全屏显示
            this.mp4Model =true
            this.playerOptions.sources[0].src =fileUrl 
        },
        //目录新增、修改保存
        modal_ok(){
            var vm =this
            if (!this.formCol.CNAME){
                this.$Message.error('本级目录名称不能为空' )
            }else{
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_user+'&password='+this.v_pwd,
                    data: {p_json:JSON.stringify(this.formCol), p_table:'HR_DOC'},
                }).then(res=>{      
                    if(res.data.result =='OK')  {          
                        vm.addModal =false   
                        vm.getData(vm.treeid)
                        // let node = vm.$refs['eltree'].getNode(vm.treeid)
                        // if (vm.addtype==='edit'){
                        //     node.parent.loaded = false;
                        //     node.parent.expand()  // 主动调用展开节点方法，重新查询该节点下的所有子节点   
                        // }  
                    }else{
                        vm.$Message.error(res.data.result)
                    }        
                })
            }
        } ,
        //新增目录
        addFolder(){
            if(this.lock==='Y' ||!this.treeid){
                this.$Message.error('此目录下不能新增' )
            }else{
                this.addModal =true
                this.addtype ='create'
                this.formCol ={}
                Object.assign(this.formCol,{PARENTNO:this.treeid,PARENTNM:this.nodeLabel})
            }
        } ,  
        //编辑目录
        editFolder(){
            if(this.lock==='Y' ||!this.treeid){
                this.$Message.error('此目录下不能编辑' )
            }else{
                this.addModal =true
                this.addtype ='edit'
                this.formCol ={}
                Object.assign(this.formCol,{PARENTNO:this.parentid,CODE:this.treeid,CNAME:this.nodeLabel})
            }
        } , 
        //删除目录
        delFolder(){
            if(this.lock==='Y' ||!this.treeid){
                this.$Message.error('此目录不能删除' )
            }else{
                this.$Modal.confirm({
                    title: '删除确认',
                    content: '<p>你确定要删除此目录吗</p>',
                    onOk: () => {
                        //发起删除请求
                        var vm=this;
                        this.$axios({
                            method: 'post',
                            url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.v_user+'&password='+vm.v_pwd,
                            data: {p_key:'code',p_value:this.treeid,p_table:'hr_doc'},
                        }).then(res=>{      
                            if(res.data.code =='200')  {           
                                vm.getData(vm.parentid)     
                            }else{
                                vm.$Message.error(res.data.message)
                            }        
                        }) 
                    }
                });
            }
        } , 
        // scaledown 会将图片完整的显示在控件中，
        // 当图片的宽或高大于控件时和contain一样会等比例进行缩放直到完全显示在控件中为止
        //当图片宽和高都小于控件时和 none方式一样，不进行任何缩放，保持原身材居中显示，总结就是只能等比例缩放不能放大
        //本页全选文件
        allSelect(){
            if (this.checkAll ==='Y'){ //全选
                for (let k=0; k<this.fileList.length;k++){
                    this.fileArr.push(this.fileList[k].PKSEQ)
                }
            }else{
                this.fileArr=[]
            }
        },
        //排序方式
        fileSortBy(asc_desc){
            if (this.fileSort==='time') { //时间
                this.getFileList(this.idseq,' order by adddttm '+asc_desc) 
            }else if (this.fileSort==='size') { //大小
                this.getFileList(this.idseq,' order by filesz '+asc_desc) 
            }else if (this.fileSort==='name') { //名字
                this.getFileList(this.idseq,' order by filenm '+asc_desc) 
            }
        },
        //文件查找
        fileSearch(){
            this.srchFileList(this.idseq,this.srchFile )
        },
        //节点点击
        moveSel(data){
            if (this.fileArr.length===0){
                this.$Message.error('请选中要移动的文件' )
            }else{
                var vm=this;
                this.moveVal=data.label
                let v_cond ='('+JSON.stringify(this.fileArr).replaceAll("\"",'\'').replace('[','').replace(']','')+')'
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'auth/login/updateRec' ,
                    data: {p_key:'idseq',p_value:vm.idseq, p_table:'V_DEL_DOCDTL',p_where:' and PKseq IN '+v_cond,p_cond:' set idseq=(select idseq from hr_doc where code=\''+data.id+'\')'},
                }).then(res=>{      
                    if(res.data.code =='200')  {    
                        vm.getFileList(vm.idseq,' order by adddttm ')                       
                    }else{
                        this.$alert(res.data.result, '提示', {
                            confirmButtonText: '确定',
                            showClose:false,
                            type:'info'
                        })
                    }        
                }) 
                // 隐藏select自带的下拉框
                vm.$refs.select.blur()
            }
        },
        // 批量删除
        delFiles(){
            if(this.fileArr.length===0){
                this.$Message.error('请选中要删除的文件' )
            }else{
                let v_cond ='('+JSON.stringify(this.fileArr).replaceAll("\"",'\'').replace('[','').replace(']','')+')'
                this.$Modal.confirm({
                    title: '删除确认',
                    content: '<p>将文件放入回收站会影响到使用该文件的地方，确定放入？</p>',
                    onOk: () => {
                        //发起删除请求
                        var vm=this;
                        this.$axios({
                            method: 'post',
                            url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.v_user+'&password='+vm.v_pwd,
                            data: {p_key:'idseq',p_value:vm.idseq, p_table:'V_DEL_DOCDTL',p_cond:' and PKseq IN '+v_cond},
                        }).then(res=>{      
                            if(res.data.code =='200')  {    
                                vm.getFileList(vm.idseq,' order by adddttm  ')                       
                            }else{
                                this.$Message.error(res.data.message)
                            }        
                        }) 
                    }
                });
            }
        },
        //文件点击事件
        fileClick(index,pkseq,filepath){
            if (this.fileArr.indexOf(pkseq)===-1){
                this.fileArr.push(pkseq)
                this.curIndex=1
            }else{
                this.fileArr.splice(this.fileArr.indexOf(pkseq),1)
                this.curIndex=-1
            }
            //选中图片在右边显示            
            if (this.addList.indexOf(filepath)===-1){
                if (this.addList.length<10){
                    this.addList.push(filepath)
                }
            }else{
                this.addList.splice(this.addList.indexOf(filepath),1)
            }
            this.addqty =this.addList.length 
        },
        selDel(filepath){
            this.addList.splice(this.addList.indexOf(filepath),1)
            this.addqty =this.addList.length 
        },
        //上传文件之前的钩子
        beforeImgFile (res, file) {              
            this.fileName= getDate('year')+'-oss'     
            this.uploadData = {
                code: this.fileName ,
                p_user:this.v_user,
                p_idseq:this.idseq ,
                p_table:'hr_docdtl',
            }
            let promise = new Promise(resolve => {
            this.$nextTick(function() {
                    resolve(true);
                });
            });
            return promise;  
        },
        // 文件上传成功时的钩子
        successImgFile(res,file){
            this.getFileList(this.idseq,' order by adddttm ') //获得节点下的文件            
        },
        //文件超出指定文件大小限制的钩子
        handleMaxSize (file) {
            this.$Message.error(file.name + ' 文件太大, 不能超出2M' )
        },
        //文件格式验证错误时的钩子
        handleFormatError (file) {
            this.$Message.error('文件格式错误,'+file.name + ' 是不正确, 请选择 jpg or png or pdf or mp4' )
        },
        ascSort(){
            this.sortFlag=false
            this.fileSortBy('asc')
        },
        descSort(){
            this.sortFlag=true
            this.fileSortBy('desc')
        },
        //跳至页数
        goNext(){
            if (this.goPage){
                this.changePage(this.goPage)
                this.current =Number(this.goPage)
            }else{
                this.$alert('请输入跳至页数', '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'info'
                })
            }
        },
        //初始化文件页面
        initPage(){
            //产品明细
            this.dataCount =this.allData.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.allData.length < this.rowSize){
                this.fileList = this.allData          
            }else{
                this.fileList =this.allData.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }
        },
        //改变 每页数量
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.fileList = this.allData.slice(_start,_end);
        },
        //目录点击事件
        dirClick(id, label,idseq,icon,parentid){
            this.getBread(id,label) //获得节点全路径
            this.getPoint(id) //获得节点下的目录
            this.getFileList( idseq,' order by adddttm  ') //获得节点下的文件
            this.$refs.eltree.setCurrentKey(id)
            this.treeid =id
            this.parentid=parentid
            this.idseq= idseq
            this.lock =icon
        },
        //节点点击
        handleNodeClick(data){
            this.nodeLabel=data.label;            
            this.treeid =data.id
            this.parentid=data.parentid
            this.idseq=data.idseq
            this.lock =data.icon
            this.getBread(data.id,data.label) //获得节点全路径
            this.getPoint(data.id) //获得节点下的目录
            this.getFileList(data.idseq,' order by adddttm  ') //获得节点下的文件
        },
        //获得节点下的目录
        getPoint(id ){
            let vm =this
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/getManyRecord?username='+this.v_user+'&password='+this.v_pwd,
                data: {p_key: 'parentno',p_value: id,p_table:'v_hr_doc'}
            }).then(function(res){                  
                if(res.data.code ==='200'){
                    vm.dirList =res.data.result
                }else{
                    vm.$alert(res.data.result, '提示', {
                        confirmButtonText: '确定',
                        showClose:false,
                        type:'info'
                    }) 
                }          
            }) 
        },
        //获得节点下的文件
        getFileList(idseq,v_sort ){
            let vm =this
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/getManyRecord?username='+this.v_user+'&password='+this.v_pwd,
                data: {p_key: 'idseq',p_value: idseq,p_key2:'userno',p_value2:this.v_user,p_key3:'stfg',p_value3:'Y',p_table:'V_HR_DOCDTL',p_cond:v_sort,p_where:' and filety '+this.filety}
            }).then(function(res){                  
                if(res.data.code ==='200'){
                    //vm.fileList =res.data.result
                    vm.allData =res.data.result
                    vm.initPage() 
                }else{
                    vm.$alert(res.data.result, '提示', {
                        confirmButtonText: '确定',
                        showClose:false,
                        type:'info'
                    }) 
                }          
            }) 
        },
        //获得节点全路径
        getBread(id,label){
            var vm =this
            this.pathArr=[]
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'oss/getTreePath?username='+this.v_user+'&password='+this.v_pwd,
                data: {p_key: id,p_value: label}
            }).then(function(res){                  
                if(res.data.code ==='200'){
                    let cnameArr =res.data.result.split(',')
                    let codeArr=res.data.resultcode.split(',')
                    for (let k=0;k<codeArr.length; k++){
                        let obj ={}
                        Object.assign(obj,{'id':codeArr[k],'name':cnameArr[k]})
                        vm.pathArr.push(obj)
                    }
 
                }else{
                    this.$alert(res.data.result, '提示', {
                        confirmButtonText: '确定',
                        showClose:false,
                        type:'info'
                    }) 
                }          
            }) 
        },
        //查找文件名
        srchFileList(idseq,name ){
            let vm =this
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/getManyRecord?username='+this.v_user+'&password='+this.v_pwd,
                data: {p_key: 'idseq',p_value: idseq,p_key2:'userno',p_value2:this.v_user,p_key3:'stfg',p_value3:'Y',p_table:'V_HR_DOCDTL',p_cond:' and filenm like \'%'+name+'%\'',p_where:' and filety '+this.filety}
            }).then(function(res){                  
                if(res.data.code ==='200'){
                    vm.allData =res.data.result
                    vm.initPage() 
                }else{
                    vm.$alert(res.data.result, '提示', {
                        confirmButtonText: '确定',
                        showClose:false,
                        type:'info'
                    }) 
                }          
            }) 
        },
        // 获取 树形控件数据
        getData(v_id) {  
            let vm=this
            vm.loadingtree=true
            vm.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'sysprivs/getElTreeData?username='+vm.v_user+'&password='+vm.v_pwd,
                data: {p_table:'V_HR_DOC'}
            }).then(function(res){                  
                vm.treeData =res.data  
                if(res.data.length > 0 ){
                    vm.$nextTick().then(() =>{
                        //使用setCurrentKey方法的时候必须将其放在nextTick中,要等数据更新渲染完再调用,否则找不到对应元素
                        vm.$refs.eltree.setCurrentKey(v_id)
                    })
                }
                vm.loadingtree=false               
            })                              
        },
    }
}
</script>
<style>
    .atooltip{
      border: #1480ce 2px solid !important;
    }
    .el-tooltip__popper[x-placement^=right] .popper__arrow::after {
      border-right-color: #1480ce !important;
    }
    .el-tooltip__popper[x-placement^=right] .popper__arrow{
        border-right-color: #1480ce !important;
    }  
   
    .el-checkbox__inner{
        border-color: #5cadff;
    }  
</style> 
<style scoped lang="less"> 
    .wraper{
        width:1040px;
        margin:1px auto;
    }
    .pageNext{
        margin-bottom:5px;
        overflow: hidden;
        line-height:30px;
        border-left:1px solid #ccc;
        border-right:1px solid #ccc;
        border-bottom:1px solid #ccc;
    }
    .divtext{
        width:90px; 
        text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
        overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
        white-space:nowrap; //禁止换行
        height:20px;
        text-align:center;
        line-height:20px;
        margin-bottom:10px;
        margin-left:10px;
 
    }
    .dircss2{
        border:1px solid #5cadff;
        width:90px;
        height:80px;
        display:flex;
        flex-direction:column;
        text-align:center;
        margin-right:18px;
        margin-left:10px;
        margin-top:10px;
        margin-bottom:5px;
    }
    .dircss2:hover{
        cursor: pointer;
        border:1px solid #409EFF;
        z-index: 1;
        .picsrch{
            right:3px;
            top:1px; 
            position:absolute;
            display:inline;
        }
    }
    .dircss{
        border:1px solid #ccc;
        width:90px;
        height:80px;
        display:flex;
        flex-direction:column;
        text-align:center;
        margin-right:18px;
        margin-left:10px;
        margin-top:10px;
        margin-bottom:5px;
    }
    .dircss:hover{
        cursor: pointer;
        border:1px solid #409EFF;
        z-index: 1;
        .picsrch{
            right:3px;
            top:1px; 
            position:absolute;
            display:inline;
        }
    }
    .picsrch{
        right:3px;
        top:1px; 
        position:absolute;
        display:none;
    }
    .picSel{
        cursor: pointer;
        border:1px solid #ccc;
        margin:10px auto;
        text-align:center;
        width: 100px;
        height:80px;
    }
    .picSel:hover{
        cursor: pointer;
        border:1px solid #409EFF;
        z-index: 10;
        .picdel{
            right:-37px;
            top:-84px; 
            position:relative;
            display:inline;
        }
    }
    .picdel{
        position:absolute;
        display:none;
    }
    .selected{
         border-color: red;       
    }
  .selected:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    border-left:2px solid red;
    border-top: 30px solid red;
    border-right: 30px solid transparent;
  }
  .selected:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 8px;
    background: transparent;
    top: 4px;
    left: 1px;
    border: 2px solid white;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-55deg);
    -ms-transform: rotate(-55deg);
    transform: rotate(-55deg);
    z-index: 9;
  }
 
</style>